<template>
    <div class="user-detail">
        <div class="user-info" v-if="userDetail">
            <div class="nick-name">
                <div>
                    <img src="https://cdn.lidian-icharging.com/_aafc47854efc4543a99bff244af30fde.png " alt="">
                </div>
            </div>
            <div class="right-info">
                <div class="name-content">
                    <div class="phone">车主手机: <span>{{ detailObj.mobile }}</span></div>
                    <div class="car-name">用户昵称 <span>{{ detailObj.nickName }}</span> </div>
                </div>
                <div>
                    <span class="label-text">创建时间</span>
                    <span class="text-content">{{ detailObj.createTime }}</span>
                </div>
                <div>
                    <span class="label-text">账号状态</span>
                    <span class="text-content">{{ detailObj.isValid == 'Y' ? '正常' : '停用' }}</span>
                    <span class="edit-action" @click="dialogVisibleState = true">变更<img class="edit-icon" src="@/assets/edit-outlined.png" alt=""></span>
                    
                </div>
            </div>
        </div>
        <div class="style-slot" style="width: 100%;background: linear-gradient(91deg, #EBF5F7 0.07%, #F5F2F0 100%);">
            <div class="style-slot" style="width: 100%;height: 10px;border-radius: 8px 8px 0 0; background: #fff;">
            </div>
        </div>
        <div class="data-container">
            <!-- 消息信息 -->
            <div>
                <div class="consume-title">
                    消费信息
                </div>
                <div class="consume-box" v-if="updateInstructView">
                    <div class="user-amount">
                        <div class="consume-data">
                            <div>
                                <img src="@/assets/detail1.png" alt="">
                            </div>
                            <div>
                                <p>账户总余额 (元)</p>
                                <div class="data-nums">
                                    {{ detailObj.amount }}
                                </div>
                            </div>
                        </div>
                        <div class="consume-data">
                            <div>
                                <img src="@/assets/detail2.png" alt="">
                            </div>
                            <div>
                                <p>账户余额 (元) <span style="cursor:pointer;" @click="handleShowDonateModel(0)">提现</span>
                                </p>
                                <div class="data-nums">
                                    {{ detailObj.accountAmount }}
                                </div>
                            </div>
                        </div>
                        <div class="consume-data">
                            <div>
                                <img src="@/assets/detail3.png" alt="">
                            </div>
                            <div>
                                <p>奖励金余额 (元) <span style="cursor:pointer;" @click="handleShowDonateModel(1)">赠送</span>
                                </p>
                                <div class="data-nums">
                                    {{ detailObj.rewardAmount }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accumulate-money">
                        <div class="data-list">
                            <div style="width: 240px; margin-right: 32px;">
                                <div class="item-data">累计订单金额 (元) <span>{{ detailObj.orderAmount }}</span></div>
                                <div class="item-data">累计充电时长 (小时) <span>{{ detailObj.chargeTime }}</span></div>
                                <div class="item-data">累计充电量 (度) <span>{{ detailObj.chargeKwh }}</span></div>
                            </div>
                            <div>
                                <div class="item-data">累计订单服务费 (元) <span>{{ detailObj.serviceAmount }}</span></div>
                                <div class="item-data">累计优惠金额 (元) <span>{{ detailObj.discountAmount }}</span></div>
                                <div class="item-data">累计充电次数 (次) <span>{{ detailObj.chargeCount }}</span></div>
                            </div>
                        </div>

                        <div class="data-list">
                            <div style="width: 240px;margin-right: 32px;">
                                <div class="item-data">单均金额 (元)<span>{{ detailObj.avgAmount }}</span></div>
                                <div class="item-data">单均充电时长 (小时) <span>{{ detailObj.avgChargeTime }}</span></div>
                                <div class="item-data">单均充电量 (度) <span>{{ detailObj.avgChargeKwh }}</span></div>
                            </div>
                            <div>
                                <div class="item-data">单均服务费 (元) <span>{{ detailObj.avgServiceAmount }}</span></div>
                                <div class="item-data">单均优惠金额 (元) <span>{{ detailObj.avgDiscountAmount }}</span></div>
                                <div class="item-data">月均充电次数 (次) <span>{{ detailObj.avgChargeCount }}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 车辆信息 -->
            <div class="car-body">
                <div class="car-header">
                    <div class="title">车辆信息</div>
                    <div class="search-box">
                        <el-input maxlength="15" style="width: 240px" placeholder="搜索车牌号码" clearable
                            v-model="carInfo.carNo" @clear="handleClearContent" @change="handleClearContent">
                            <template #suffix>
                                <i class="el-icon-search search-icon" @click.stop="searchData"
                                    style="margin-right: 10px; line-height: 32px;cursor: pointer; color: #86909C;"></i>
                            </template>
                        </el-input>
                    </div>
                </div>
                <div class="table-list">
                    <el-table v-loading="tableLoading" :data="tableData"
                        :header-cell-style="{ background: '#F7F8FA !important', color: '#1D2129  !important', fontSize: '14px' }"
                        style="width: 100%">
                        <el-table-column prop="id" label="车辆ID" min-width="70" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="carNo" label="车辆号码" min-width="100"
                            show-overflow-tooltip></el-table-column>
                        <el-table-column prop="carName" label="品牌车型" min-width="100" show-overflow-tooltip>
                            <template slot-scope="{ row }">
                                <span v-if="row.carName && row.carName != ''">{{ row.carName }}</span>
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="vin" label="车VIN码" min-width="90" show-overflow-tooltip>
                            <template slot-scope="{ row }">
                                <span v-if="row.vin && row.vin != ''">{{ row.vin }}</span>
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="mobiles" label="关联其他车主" min-width="100">
                            <template slot-scope="{ row }">
                                <div v-if="row.mobiles && row.mobiles.length > 0">
                                    <div style="color: #057C98;" v-for="(item_, index_) in row.mobiles">
                                        {{ item_ }}
                                    </div>
                                </div>
                                <div v-else>-</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="remark" label="备注" min-width="100" show-overflow-tooltip>
                            <template slot-scope="{ row }">
                                <span v-if="row.remark && row.remark != ''">{{ row.remark }}</span>
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="lastUpdateTime" label="最后编辑时间" min-width="150"></el-table-column>
                        <el-table-column prop="lastUpdateBy" label="编辑人" min-width="120"
                            show-overflow-tooltip></el-table-column>
                        <el-table-column prop="orderNo" label="操作" min-width="80" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span @click="handleEdit(scope.row)" type="primary"
                                    style="color: #057C98;cursor: pointer;">编辑</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <!-- 变更状态 -->
        <div class="change-state">
            <el-dialog title="变更状态" :visible.sync="dialogVisibleState" :append-to-body="true"
                @close="handleDialogStateClose" class="custom-close-button" width="448px">
                <div class="donate-title" style="padding: 16px;display: flex;align-items: center;"> <img
                        src="@/assets/dreaw-icon.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
                    <span style="color: #1D2129;font-family: 'PingFang SC';font-size: 16px;font-weight: 500;">车主手机号：{{
                        detailObj.mobile }}</span>
                </div>
                <div class="donate-form">
                    <el-form ref="formDataStateRef" :model="formDataState" label-position="left"
                        :rules="formDataStateRule">
                        <div>
                            <el-form-item class="nav-form-item"
                                style="padding: 20px 24px; margin-bottom: 0;border-radius: 8px 8px 0 0;background-color: #FFF;">
                                <div> 账号状态</div>
                                <div class="list-item">
                                    <div>
                                        <el-form-item>
                                            <el-radio-group v-model="formDataState.isValid" @change="changeNodeType">
                                                <el-radio label="Y"
                                                    style="margin-right: 16px;color: #4E5969;">正常</el-radio>
                                                <el-radio label="N"
                                                    style="margin-right: 8px;color: #4E5969;">停用</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="item-input" v-if="isValidateView">
                                        <el-form-item class="nav-form-item" key="3" label="停用原因" prop="disableDesc">
                                            <el-input type="textarea" v-model="formDataState.disableDesc"
                                                :maxlength="30" placeholder="请输入停用原因" rows="4"
                                                show-word-limit></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleState = false">取 消</el-button>
                    <el-button type="primary" @click="handleSureState">保 存</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 余额提现&平台赠送 -->
        <div class="withdrawal-body">
            <el-dialog :title="isWithdrawalFlag ? '账户余额提现' : '平台赠送'" :visible.sync="dialogVisibleDonate"
                :append-to-body="true" @close="handleDialogDonateClose" :close-on-click-modal="false" width="448px">
                <div class="withdrawal-content" style="">
                    <div class="withdrawal-title"> <img src="@/assets/withdrawal.png" alt=""> {{ isWithdrawalFlag ?
                        '提现成功后金额将原路退回给车主 ，请谨慎操作！' : '平台赠送金额可用于充电消费，含电费和服务费' }}
                    </div>
                    <div class="withdrawal-form">
                        <el-form ref="formDataDonateRef" :model="formDataDonate" label-position="left"
                            :rules="formDataDonateRule">
                            <div>
                                <el-form-item class="nav-form-item">
                                    <div> 车主手机</div>
                                    <div class="list-item">
                                        <div class="item-input">
                                            <el-input maxlength="11" v-model="formDataDonate.mobile" :disabled="true" />
                                        </div>
                                        <div class="item-money" v-if="isWithdrawalFlag">可提现余额 (元)：{{ !showUserAmount ?
                                            '--' : showUserAmount
                                            }}</div>
                                        <div class="item-money" v-else>奖励金余额 (元)：{{ !showUserAmount ?
                                            '--' : showUserAmount
                                            }}</div>
                                    </div>
                                </el-form-item>
                            </div>
                            <div v-if="isWithdrawalFlag">
                                <el-form-item class="nav-form-item" prop="amount">
                                    <div> <span style="color:#DF3737;">*</span> 提现金额 (元)</div>
                                    <div class="list-item">
                                        <div class="item-input">
                                            <el-input v-model="formDataDonate.amount" @input="handleInput" placeholder="请输入" show-word-limit
                                                autocomplete="off" clearable />
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                            <div v-else>
                                <el-form-item class="nav-form-item" prop="giftAmount">
                                    <div> <span style="color:#DF3737;">*</span> 赠送金额 (元)</div>
                                    <div class="list-item">
                                        <div class="item-input">
                                            <el-input v-model="formDataDonate.giftAmount" placeholder="请输入" @input="handleInput2"
                                                show-word-limit autocomplete="off" clearable />
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item class="nav-form-item">
                                    <!-- 提现 -->
                                    <div v-if="isWithdrawalFlag">
                                        <el-form-item prop="withdrawReason" style="margin-bottom: 0;">
                                            <div> <span style="color:#DF3737;">*</span> 提现原因</div>
                                            <div class="list-item">
                                                <div class="item-input">
                                                    <el-input type="text" v-model="formDataDonate.withdrawReason"
                                                        maxlength="30" placeholder="请输入" show-word-limit clearable />
                                                </div>
                                            </div>
                                        </el-form-item>
                                    </div>
                                    <!-- 赠送 -->
                                    <div v-else>
                                        <div> <span style="color:#DF3737;">*</span> 赠送原因</div>
                                        <div class="list-item">
                                            <div>
                                                <el-radio-group v-model="formDataDonate.giftReasonSel"
                                                    @change="changeGiveNodeType">
                                                    <el-radio :label="1"
                                                        style="margin-right: 16px;color: #4E5969;">测试</el-radio>
                                                    <el-radio :label="2"
                                                        style="margin-right: 8px;color: #4E5969;">其他</el-radio>
                                                </el-radio-group>
                                            </div>
                                            <div class="item-input" v-if="isGiveView">
                                                <el-form-item prop="giftReasonText">
                                                    <el-input type="text" maxlength="30"
                                                        v-model="formDataDonate.giftReasonText" placeholder="请输入"
                                                        clearable />
                                                </el-form-item>
                                            </div>
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item class="nav-form-item" prop="password">
                                    <div> <span style="color:#DF3737;">*</span> 登录密码</div>
                                    <div class="list-item">
                                        <div class="item-input">
                                            <el-input type="password" v-model="formDataDonate.password"
                                                autocomplete="new-password" placeholder="请输入账号登录密码验证本人操作"
                                                show-word-limit clearable />
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleDonate = false">取 消</el-button>
                    <el-button type="primary" @click="handleSureDonate">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 编辑车辆信息 -->
        <editCarDialog ref="editCarDialogRef" @onload="getUserCarsFn"></editCarDialog>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits } from 'vue'

import {
    getUserDetail,
    getUserCars,
    changeUserState,
    getWalletWithdraw,
    getRewardGift
} from '@/api/registerUser'
import editCarDialog from "./editCarDialog.vue";
import {
    Message
} from "element-ui";

const props = defineProps({
    mobile: String,
    userId: Number
});
const emit = defineEmits(['updateList'])

const userDetail = ref(false)
const updateInstructView = ref(false)
const detailObj = reactive({})
const showUserAmount = ref('')
const isWithdrawalFlag = ref(false)
const dialogVisibleState = ref(false)
const isValidateView = ref(false)
const isGiveView = ref(false)
const tableLoading = ref(false)
const dialogVisibleDonate = ref(false)
const editCarDialogRef = ref(null)
const formDataStateRef = ref(null);
const formDataDonateRef = ref(null);
const tableData = ref([])
const formDataState = reactive({
    userId: props.userId,
    isValid: 'Y',
    disableDesc: '',
})
const formDataStateRule = reactive({
    disableDesc: [
        { required: true, message: "请输入停用原因", trigger: "blur" },
    ],
})

const carInfo = reactive({ //车辆信息
    carNo: '',
    mobile: props.mobile,
    pageNo: 1,
    pageSize: 20,
})

const formDataDonate = reactive({
    amount: '',
    openid: '',
    withdrawReason: '',
    mobile: '',
    password: '',
    giftAmount: '',
    giftReasonSel: 1,
    giftReason: '',
    giftReasonText: '',
})
const formDataDonateRule = reactive({
    amount: [
        { required: true, message: "请输入提现金额", trigger: "blur" },
        { pattern: /^\d+(\.\d+)?$/, message: '请输入数字', trigger: 'blur' },
        {
            validator: (rule, value, callback) => {
                console.log(showUserAmount, 777,value);
                const decimalPlaces = (value.split('.')[1] || '').length;
                if (decimalPlaces > 2) {
                    callback(new Error('小数点后不能超过两位'));
                } else if (Number(value) > Number(showUserAmount.value)) {
                    callback(new Error('不能超出可提现金额'));
                } else {
                    callback();
                }
            },
            trigger: 'blur'
        }
    ],
    password: [
        { required: true, message: "请输入密码", trigger: "blur" },
    ],
    withdrawReason: [
        { required: true, message: "请输入提现原因", trigger: "blur" },
    ],
    giftAmount: [
        { required: true, message: "请输入赠送金额", trigger: "blur" },
        // { pattern: /^\d+(\.\d+)?$/, message: '请输入数字', trigger: 'blur' },
        {
            validator: (rule, value, callback) => {
                console.log(showUserAmount, 777);
                const decimalPlaces = (value.split('.')[1] || '').length;
                if (decimalPlaces > 2) {
                    callback(new Error('小数点后不能超过两位'));
                } else if (value <= 0) {
                    callback(new Error('赠送金额不能小于等于0'));
                } else if (value > 500) {
                    callback(new Error('不能超出最大赠送余额500元'));
                } else {
                    callback();
                }
            },
            trigger: 'blur'
        }
    ],
    giftReasonText: [
        { required: true, message: "请输入赠送原因", trigger: "blur" },
    ],
})

function insertDotInLicensePlate(licensePlate) {
    if (typeof licensePlate !== "string" || !/^.{7,8}$/.test(licensePlate)) {
        throw new Error(
            "Invalid license plate format. It should be a string containing exactly 7 digits."
        );
    }
    return licensePlate.slice(0, 2) + "·" + licensePlate.slice(2);
}

function getUserDetailFn() { //详情用户信息
    getUserDetail({ mobile: props.mobile })
        .then((res) => {
            if (res.data) {
                userDetail.value = false
                Object.assign(detailObj, res.data)

                formDataState.isValid = res.data.isValid
                isValidateView.value = res.data.isValid == 'Y' ? false : true

                updateInstructView.value = true
                userDetail.value = true
                if (dialogVisibleDonate.value) {
                    showUserAmount.value = res.data.accountAmount
                }
            }
        })
        .catch((e) => { });
}
getUserDetailFn()

function changeNodeType(e) {
    isValidateView.value = e == 'Y' ? false : true
}

function handleDialogStateClose() {
    formDataStateRef.value.clearValidate();
    formDataState.isValid = 'Y'
    isValidateView.value = false
}
function handleSureState() {
    formDataStateRef.value.validate((valid) => {
        if (valid) {
            changeUserState(formDataState)
                .then((res) => {
                    if (res.message.code == 0) {
                        Message.success('操作成功')
                        getUserDetailFn()
                        emit('updateList')
                        dialogVisibleState.value = false
                    } else {
                        Message(res.message.message)
                    }
                }).catch((e) => {
                    Message.error(e.message.message)
                });
        }
    })
}

function getUserCarsFn() {
    getUserCars(carInfo)
        .then((res) => {
            if (res.message.status == 200) {
                tableData.value = res.data.records;
                tableData.value.forEach(item => {
                    if (item.carNo && item.carNo != '') {
                        item.carNo = insertDotInLicensePlate(item.carNo)
                    }
                })
            }
        }).catch((e) => { });
}
getUserCarsFn()

function searchData() {
    tableData.value = []
    getUserCarsFn()
}
function handleClearContent() {
    tableData.value = []
    getUserCarsFn()
}

//编辑车辆信息
function handleEdit(row) {
    editCarDialogRef.value.show(row)
}

// 提现 & 赠送
function handleShowDonateModel(flag) {
    // flag:0提现,flag:1赠送
    isWithdrawalFlag.value = flag == 0 ? true : false
    formDataDonate.mobile = detailObj.mobile
    showUserAmount.value = flag == 0 ? detailObj.accountAmount : detailObj.rewardAmount
    formDataDonate.openid = detailObj.openid
    dialogVisibleDonate.value = true
}
function handleDialogDonateClose() {
    dialogVisibleDonate.value = false
    formDataDonateRef.value.clearValidate();
    formDataDonateRef.value.resetFields();
}
//提现 & 赠送
function handleSureDonate() {
    if (isWithdrawalFlag.value) {
        console.log(formDataDonate, '提现');
        formDataDonateRef.value.validate((valid) => {
            if (valid) {
                console.log(formDataDonate, 88899);
                getWalletWithdraw(formDataDonate)
                    .then((res) => {
                        if (res.message.code == 0) {
                            Message.success('操作成功')
                            updateInstructView.value = false
                            getUserDetailFn()
                            dialogVisibleDonate.value = false
                        } else {
                            Message(res.message.message)
                        }
                    }).catch((e) => {
                        Message.error(e.message.message)
                    });
            }
        })
    } else {
        formDataDonateRef.value.validate((valid) => {
            formDataDonate.giftReason = formDataDonate.giftReasonSel == 1 ? '测试' : formDataDonate.giftReasonText
            if (valid) {
                getRewardGift(formDataDonate)
                    .then((res) => {
                        if (res.message.code == 1015) {
                            Message(res.message.message)
                        } else {
                            Message.success('操作成功')
                            updateInstructView.value = false
                            getUserDetailFn()
                            dialogVisibleDonate.value = false
                        }
                    }).catch((e) => {
                        Message.error(e.message.message)
                    });
            }
        })

    }

}

//赠送
function changeGiveNodeType(e) {
    isGiveView.value = e == 1 ? false : true
}

function handleInput(value){
    if (value.includes('.') && value.split('.')[1] && value.split('.')[1].length > 2) {
        const fourthDigit = value.split('.')[1][2];
        if (fourthDigit && fourthDigit !== '0') {
          value = value.substring(0, value.length - 1);
        }
      }
      const formattedValue = value.replace(/[^\d.]/g, '');
      formDataDonate.amount = formattedValue
    //   if(isWithdrawalFlag){
    //     formDataDonate.amount = formattedValue
    //   }else{
    //     formDataDonate.giftAmount = formattedValue
    //   }
}

function handleInput2(value){
    
    if (value.includes('.') && value.split('.')[1] && value.split('.')[1].length > 2) {
        const fourthDigit = value.split('.')[1][2];
        if (fourthDigit && fourthDigit !== '0') {
          value = value.substring(0, value.length - 1);
        }
      }
      const formattedValue = value.replace(/[^\d.]/g, '');
      formDataDonate.giftAmount = formattedValue
}
</script>

<style lang="scss" scoped>
.user-detail {
    // padding-bottom: 30px;

    .user-info {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 24px 100px 24px 24px;
        color: #1D2129;
        font-size: 16px;
        font-weight: 500;
        background: linear-gradient(91deg, #EBF5F7 0.07%, #F5F2F0 100%);

        .nick-name {
            display: flex;
            

            img {
                margin-right: 16px;
                vertical-align: middle;
                width: 64px;
                height: 64px;
                border-radius: 50%;
                border: 1px solid rgba(255, 255, 255, 0.45);
                box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
            }
        }

        .right-info {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 4px;

            .name-content {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .phone {
                    margin-bottom: 20px;
                    color: var(--PC-, #1D2129);
                    font-size: 18px;
                    font-weight: 500;
                }

                .car-name {
                    color: var(--PC-, #86909C);
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-weight: 400;

                    span {
                        margin-left: 12px;
                        color: var(--PC-, #1D2129);
                        font-family: "PingFang SC";
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }

            .label-text,
            .text-content {
                font-size: 14px;
                font-weight: 400;
            }

            .label-text {
                color: var(--PC-, #86909C);
            }

            .text-content {
                margin-left: 12px;
                margin-right: 2px;
                color: var(--PC-, #1D2129);
            }

            .edit-action {
                display: inline-flex;
                align-items: center;
                color: #057C98;
                font-size: 14px;
                cursor: pointer;
            }

            .edit-icon {
                vertical-align: middle;
                width: 16px;
                height: 16px;
            }
        }
    }

    .data-container {
        padding: 14px 24px 24px;
        background-color: #fff;
        .consume-title {
            color: var(--PC-, #1D2129);
            font-size: 16px;
            font-weight: 500;
        }

        .consume-box {
            margin-top: 16px;
            padding: 24px;
            border-radius: 8px;
            box-shadow: 0px 2px 16px 0px rgba(59, 78, 76, 0.08);

            .user-amount {
                display: flex;
                padding-bottom: 24px;

                .consume-data {
                    width: 33.333%;
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 12px;
                        width: 48px;
                        height: 48px;
                    }

                    p {
                        margin-bottom: 8px;
                        color: var(--PC-, #4E5969);
                        font-size: 14px;
                        font-weight: 400;

                        span {
                            margin-left: 8px;
                            color: var(--PC-, #057C98);
                            font-family: "PingFang SC";
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }

                    .data-nums {
                        color: var(--PC-, #1D2129);
                        font-size: 24px;
                        font-weight: 600;
                        font-family: "D-DIN-PRO";
                    }
                }
            }

            .accumulate-money {
                display: flex;
                justify-content: space-between;

                .data-list {
                    display: flex;
                    // justify-content: space-between;
                    width: 49%;
                    padding: 16px 20px 4px;
                    border-radius: 8px;
                    background: #F9FAFB;
                }

                .item-data {
                    margin-bottom: 12px;
                    color: var(--PC-, #4E5969);
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-weight: 400;

                    span {
                        margin-left: 10px;
                        color: var(--PC-, #1D2129);
                        font-size: 14px;
                        font-weight: 600;
                        font-family: "PingFang SC";
                    }
                }

                .money {
                    color: var(--PC-, #1D2129);
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }

        .car-body {
            margin-top: 32px;
            margin-bottom: 30px;

            .car-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                    color: var(--PC-, #1D2129);
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-weight: 500;
                }
            }

            .table-list {
                margin-top: 16px;
            }
        }
    }
}


/* 变更状态 */
::v-deep .el-dialog {
    border-radius: 8px;
}

::v-deep .el-dialog__body {
    /* 自定义样式 */
    padding: 0;
    background: linear-gradient(91deg, #EBF5F7 0.07%, #F5F2F0 100%);
}


::v-deep .el-dialog__headerbtn .el-dialog__close {
    /* 自定义样式 */
    color: #1D2129;
}

::v-deep .el-dialog__footer {
    /* 自定义样式 */
    border-top: 1px solid #C9CDD4;
}

// 余额提现
.withdrawal-content {
    padding: 20px;
    border-top: 1px solid #E5E6EB;
    background-color: #fff;

    .withdrawal-title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding: 9px 12px;
        border-radius: 4px;
        border: 1px solid #FFE58F;
        background: #FFFBE6;
        color: rgba(0, 0, 0, 0.65);
        font-family: "PingFang SC";
        font-size: 14px;
        font-weight: 400;

        img {
            margin-right: 7px;
            width: 22px;
            height: 22px;
        }
    }

    .list-item {
        display: flex;

        .item-input {
            flex: 1;
        }

        .item-money {
            margin-left: 8px;
        }
    }
}
</style>