<template>
  <div class="main">
    <div class="content-box">
      <el-input
        maxlength="15"
        style="width:280px;"
        placeholder="搜索手机号"
        v-model="keyWord"
      >
        <el-button
          slot="suffix"
          @click="searchData"
          type="primary"
          icon="el-icon-search"
          style="border-radius:0 4px 4px 0;margin-right:-5px"
          >搜索
        </el-button>
      </el-input>
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="openId"
          label="微信ID"
          align="center"
          min-width="80"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="申请金额"
          :formatter="amount"
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="giftAmount"
          label="赠送扣除"
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="beforeAmount"
          label="实际余额"
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          label="手机"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="requestTime"
          label="申请时间"
          align="center"
          min-width="160"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="提现状态"
          :formatter="formatStatus"
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="handleTime"
          label="处理时间"
          align="center"
          min-width="160"
        ></el-table-column>
        <el-table-column
          prop="handleAccount"
          label="处理人"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button
                type="primary"
                v-if="scope.row.status == '0' || scope.row.status == '1'"
                @click="transferHandler(scope.row)"
                >手动提现</el-button
              >
              <el-button type="primary" disabled v-else>已处理</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page + 1"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
      <el-dialog title="提现" :visible.sync="dialogVisibleTransfer" width="40%">
        <div class="order">
          <el-form
            ref="formTransfer"
            :model="formDataTransfer"
            label-width="100px"
          >
            <el-form-item class="nav-form-item" label="提现状态">
              <el-select
                v-model="formDataTransfer.status"
                placeholder="请选择状态"
              >
                <el-option
                  v-for="item in statusDic"
                  :key="item.code"
                  :label="item.lable"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="nav-form-item"
              label="提现金额"
              prop="amount"
              style="margin-top:20px;"
            >
              <el-input
                maxlength="256"
                type="text"
                v-model="formDataTransfer.amount"
                style="width:300px;"
                placeholder="请确认提现金额"
                clearable
              />
            </el-form-item>
            <el-form-item
              class="nav-form-item"
              label="奖励金额扣除"
              prop="giftAmount"
              style="margin-top:20px;"
            >
              <el-input
                maxlength="256"
                type="text"
                v-model="formDataTransfer.giftAmount"
                style="width:300px;"
                placeholder="请确认扣除奖励金额"
                clearable
              />
            </el-form-item>
            <el-form-item
              class="nav-form-item"
              label="备注"
              prop="remark"
              style="margin-top:20px;"
            >
              <el-input
                maxlength="256"
                type="text"
                v-model="formDataTransfer.remark"
                style="width:300px;"
                placeholder="请输入备注"
                clearable
              />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisibleTransfer = false"
            >取 消</el-button
          >
          <el-button
            :loading="buttonLoading"
            type="primary"
            @click="confimTransferHandler"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { editRefund, refundList } from "@/api/refund";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      dialogVisibleMobile: false,
      operatorId: null,
      options: [],
      tableData: [],
      nickName: "",
      avatar: "",
      formData: {
        mobile: "",
        userId: "",
      },
      statusDic: [
        {
          lable: "待审核",
          code: 0,
        },
        { lable: "审核中", code: 1 },
        { lable: "已提现", code: 2 },
        { lable: "提现失败", code: 3 },
      ],
      page: 0,
      pageSize: 10,
      currentPage: 1,
      loading: true,
      total: 0,
      data: {},
      keyWord: "",
      dialogVisibleTransfer: false,
      formDataTransfer: {
        id: "",
        status: "",
        remark: "",
        amount: 0,
        giftAmount: 0,
      },
      dataRow: {},
      buttonLoading: false,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    transferHandler(row) {
      this.dataRow = row;
      this.formDataTransfer.id = row.id;
      this.formDataTransfer.remark = row.remark;
      this.formDataTransfer.status = +row.status;
      this.formDataTransfer.amount = row.amount;
      this.formDataTransfer.giftAmount = row.giftAmount;
      this.dialogVisibleTransfer = true;
    },
    confimTransferHandler() {
      if (!this.formDataTransfer.status) {
        this.$message.error("请选择处理状态");
        return;
      }
      this.$refs.formTransfer.validate((valid) => {
        if (valid) {
          this.buttonLoading = true;
          editRefund(this.formDataTransfer)
            .then((res) => {
              this.buttonLoading = false;
              if (res.message.code != 0) {
                this.$message.error(res.message.message);
              } else {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.dialogVisibleTransfer = false;
                this.getDataList();
              }
            })
            .catch((err) => {
              this.buttonLoading = false;
              this.$message.error(JSON.stringify(err));
            });
        }
      });
    },
    searchData() {
      this.page = 0;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.tableData = [];
      let param = {
        mobile: this.keyWord,
        userId: this.operatorId,
        page: this.page,
        size: this.pageSize,
      };
      refundList(param).then((res) => {
        if (res.message.status == 200) {
          this.tableData = [...res.data.content];
          this.total = res.data.totalElements;
        }

        this.loading = false;
      });
    },
    amount(row) {
      return "￥" + row.amount;
    },
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getDataList();
    },
    formatStatus(row) {
      switch (row.status) {
        case "0":
          return "待审核";
        case "1":
          return "审核中";
        case "2":
          return "已提现";
        case "3":
          return "提现失败";
      }
    },
    getStatusLabel(status) {
      switch (status) {
        case "待审核":
          return "0";
        case "审核中":
          return "1";
        case "已提现":
          return "2";
        case "提现失败":
          return "3";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}

.title {
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.order {
  display: flex;
  flex-direction: column;
  color: #333;

  .text {
    color: var(--current-color);
    width: 100px;
  }

  .order-1 {
    padding: 8px 10px;
    display: flex;
  }
}
</style>
