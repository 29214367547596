<template>
  <div class="main" style="padding: 16px">
    <div class="content-box" style="padding: 16px">
      <div class="search-box">
        <el-input
          maxlength="15"
          style="width: 254px"
          placeholder="搜索昵称/手机号"
          clearable
          v-model.trim="tableParams.keyWord"
          @clear="handleClearContent"
          @change="handleClearContent"
        >
          <template #suffix>
            <i
              class="el-icon-search search-icon"
              @click.stop="searchData"
              style="margin-right: 10px; line-height: 32px;cursor: pointer; color: #86909C;"
            ></i>
          </template>
        </el-input>
      </div>
      <div class="table-list">
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          :header-cell-style="{
            background: '#F7F8FA !important',
            color: '#1D2129  !important',
            fontWeight: '600 !important',
          }"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="userName"
            label="昵称"
            min-width="120"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column prop="headImage" label="头像" min-width="120">
            <template slot-scope="scope">
              <img
                v-if="scope.row.headImage"
                :src="scope.row.headImage"
                style="
                width: 32px;
                height: 32px;
                border-radius: 50%;
                overflow: hidden;
              "
              />
              <img
                v-else
                src="https://cdn.lidian-icharging.com/_aafc47854efc4543a99bff244af30fde.png"
                style="
                width: 32px;
                height: 32px;
                border-radius: 50%;
                overflow: hidden;
              "
              />
            </template>
          </el-table-column>
          <el-table-column prop="mobile" label="手机" min-width="120">
            <template slot-scope="{ row }">
              <span v-if="row.mobile && row.mobile != ''">{{
                row.mobile
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="mobile" label="绑定车辆" min-width="100">
            <template slot-scope="{ row }">
              <div
                v-if="row.cars && row.cars.length > 0"
                style="cursor: pointer;"
              >
                <el-popover placement="top-start" title="" trigger="hover">
                  <div class="flex-y-center">
                    <div
                      v-for="item in row.cars"
                      :key="item.id"
                      style="line-height: 26px"
                    >
                      {{ item.carNo }}
                    </div>
                  </div>
                  <div slot="reference" class="u-flex-xy">
                    <span
                      slot="reference"
                      type="text"
                      style="color: #057C98;"
                      @click="handleCarLicenseClick(row)"
                    >
                      {{ ((row.cars || {})[0] || {}).carNo }}
                    </span>
                    <!-- <el-tag size="mini" style="margin-left: 10px" v-if="row.cars && row.cars.length > 1">
                  {{ "+" + row.cars.length }}
                </el-tag> -->
                  </div>
                </el-popover>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="amount"
            label="账户余额 (元)"
            min-width="90"
          ></el-table-column>
          <el-table-column
            prop="giftAmount"
            label="奖励金额 (元)"
            min-width="90"
          ></el-table-column>
          <el-table-column
            prop="sumAmount"
            label="总余额 (元)"
            min-width="80"
          ></el-table-column>
          <el-table-column prop="isvalid" label="状态" min-width="60">
            <template slot-scope="{ row }">
              <div style="display: flex;">
                <div
                  v-if="row.isvalid == 'Y'"
                  class="table-state"
                  style="color: #08AB67;"
                  @click="handleShowRightData(row)"
                >
                  正常
                </div>
                <div v-else class="table-state" style="color: #F56C6C;">
                  停用
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createtime"
            label="创建时间"
            min-width="150"
          ></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div class="editCell">
                <span
                  @click="handleReadyDetail(scope.row)"
                  style="margin-right: 14px;"
                  >详情</span
                >
                <span v-if="!scope.row.mobile" @click="handleMobile(scope.row)"
                  >绑定手机号</span
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paginationObj.page"
        :page-size="paginationObj.listPageSize"
        layout="total, prev, pager, next,sizes, jumper"
        :total="paginationObj.total"
      ></el-pagination>

      <el-dialog
        title="绑定手机号"
        :visible.sync="dialogVisibleMobile"
        width="448px"
      >
        <div class="order">
          <el-form
            ref="formRefund"
            :model="formUserData"
            :rules="formUserDataRule"
          >
            <div class="order-1">
              <div class="text" style="color: #606266;">用户昵称:</div>
              {{ formUserData.userName }}
            </div>
            <div class="order-1">
              <div class="text" style="color: #606266;">用户头像:</div>
              <img
                src="https://cdn.lidian-icharging.com/_aafc47854efc4543a99bff244af30fde.png "
                style="
                  width: 80px;
                  height: 80px;
                  border-radius: 80px;
                  overflow: hidden;
                "
              />
            </div>
            <el-form-item
              class=""
              prop="mobile"
              label="手机号:"
              style="margin-top: 20px;margin-left: 10px;"
            >
              <div style="display: flex;align-items: center;">
                <!-- <div class="text" style="color: #1D2129;">手机号:</div> -->
                <div>
                  <el-input
                    maxlength="15"
                    type="text"
                    v-model="formUserData.mobile"
                    style="width: 200px;"
                    placeholder="请输入手机号"
                    clearable
                  />
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleMobile = false">取 消</el-button>
          <el-button type="primary" @click="confimMobileHandler"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <!-- 详情 -->
      <el-drawer
        title="详情"
        :visible.sync="isShowDrawer"
        direction="rtl"
        :modal="false"
        :size="1208"
        :destroy-on-close="true"
      >
        <div class="drawer-tabs">
          <el-tabs v-model="activeName" @tab-click="handleSelectTab">
            <el-tab-pane label="车主信息" name="first">
              <userDetail
                v-if="showTabItem"
                :userId="sendUserId"
                :mobile="sendMobile"
                @updateList="getRegisterUserFn"
              />
            </el-tab-pane>
            <el-tab-pane label="账户流水" name="second">
              <journalDetail v-if="!showTabItem" :mobile="sendMobile" />
            </el-tab-pane>
          </el-tabs>
        </div>
        <div>
          <div class="border-style">
            <div class="radiu-body"></div>
          </div>
          <div class="drawer-detail"></div>
          <div class="footer-close-btn">
            <el-button @click="isShowDrawer = false">关闭</el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue';

import userDetail from './components/userDetail';
import journalDetail from './components/journal';

import { getRegisterUser, bindMobile } from '@/api/registerUser';
import { Message } from 'element-ui';

const formRefund = ref(null);
const tableLoading = ref(false);
const dialogVisibleMobile = ref(false);
const isShowDrawer = ref(false);
const activeName = ref('first');
const showTabItem = ref(true);
const sendMobile = ref('');
const sendUserId = ref('');
const tableData = ref([]);
const formUserData = reactive({
  userName: '',
  mobile: '',
  userId: '',
});
const formUserDataRule = reactive({
  mobile: [
    { required: true, message: '请输入手机号码', trigger: 'blur' },
    {
      pattern: /^1[3-9]\d{9}$/,
      message: '请输入正确手机号码',
      trigger: 'blur',
    },
  ],
});
const paginationObj = reactive({
  page: 1,
  listPageSize: 20,
  total: 0,
});
const tableParams = reactive({
  keyWord: '',
  userId: null,
  cardNo: '',
  pageNo: 1,
  pageSize: 20,
});

function getRegisterUserFn() {
  tableLoading.value = true;
  getRegisterUser(tableParams)
    .then((res) => {
      if (res.data && res.data.records.length > 0) {
        tableData.value = [...res.data.records];
        tableData.value.forEach((item) => {
          if (item.cars && item.cars.length > 0) {
            item.cars.forEach((car) => {
              car.carNo = insertDotInLicensePlate(car.carNo);
            });
          }
        });
      }
      paginationObj.total = res.data.total;
      tableLoading.value = false;
    })
    .catch((e) => {
      tableLoading.value = false;
    });
}
getRegisterUserFn();

function insertDotInLicensePlate(licensePlate) {
  if (typeof licensePlate !== 'string' || !/^.{7,8}$/.test(licensePlate)) {
    throw new Error(
      'Invalid license plate format. It should be a string containing exactly 7 digits.'
    );
  }
  return licensePlate.slice(0, 2) + '·' + licensePlate.slice(2);
}

function handleReadyDetail(row) {
  sendMobile.value = row.mobile;
  sendUserId.value = row.id;
  isShowDrawer.value = true;
}
function handleMobile(row) {
  formUserData.userId = row.id;
  formUserData.userName = row.userName;
  dialogVisibleMobile.value = true;
}
function searchData() {
  tableData.value = [];
  getRegisterUserFn();
}
function handleClearContent() {
  tableData.value = [];
  getRegisterUserFn();
}

function handleSelectTab(e) {
  showTabItem.value = e.index == 0 ? true : false;
}

function confimMobileHandler() {
  formRefund.value.validate((valid) => {
    if (valid) {
      bindMobile(formUserData).then((res) => {
        if (res.message.code == 0) {
          Message.success('操作成功');
          getRegisterUserFn();
          dialogVisibleMobile.value = false;
        } else {
          Message.error(res.message.message);
        }
      });
    }
  });
}

function handleSizeChange(val) {
  paginationObj.listPageSize = val;
  tableParams.pageSize = val;
  getRegisterUserFn();
}

function handleCurrentChange(val) {
  paginationObj.pageNo = val;
  paginationObj.page = val;
  tableParams.pageNo = val;
  getRegisterUserFn();
}
</script>
<style scoped lang="scss">
.main {
  padding: 16px;

  .search-box {
    margin-bottom: 12px;
  }

  // 表格
  .table-list {
    margin-top: 20px;

    .table-state {
      display: inline-flex;
      align-items: center;
      padding: 2px 8px;
      border-radius: 4px;
      font-family: 'PingFang SC';
      font-size: 12px;
      cursor: pointer;
      background: rgba(8, 171, 103, 0.06);

      img {
        margin-left: 2px;
        width: 12px;
        height: 12px;
      }
    }
  }

  ::v-deep .el-table__body .cell {
    font-size: 14px;
    color: #1d2129;
  }

  .editCell {
    color: #057c98;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }

  .order {
    display: flex;
    flex-direction: column;
    color: #333;

    .text {
      color: var(--current-color);
      width: 100px;
    }

    .order-1 {
      padding: 8px 10px;
      display: flex;
    }
  }

  .flex-y-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #057c98;
  }

  ::v-deep .el-dialog {
    border-radius: 8px;
  }

  ::v-deep .el-dialog__footer {
    padding: 10px 20px 10px;
    border-top: 1px solid #e5e6eb;
  }

  //右侧弹框
  ::v-deep .el-tabs {
    margin-right: 0;
  }

  ::v-deep .el-dialog__close {
    color: #86909c;
  }

  ::v-deep .el-drawer {
    border-radius: 8px 0 0 8px;
    // background: linear-gradient(91deg, #EBF5F7 0.07%, #F5F2F0 100%);
  }

  ::v-deep .el-drawer__close-btn {
    font-size: 18px;
  }

  ::v-deep .el-drawer__header {
    margin-bottom: 0px !important;
    padding: 16px 24px 16px;
    color: #1d2129;
    background-color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e5e6eb;
  }

  ::v-deep .el-tabs__nav-wrap {
    padding-left: 24px;
  }

  ::v-deep .el-tabs__nav-wrap::after {
    height: 1px;
  }

  ::v-deep .el-tabs__header {
    margin: 0 0 12px;
  }

  .drawer-tabs {
    padding: 10px 0 0;
  }

  .border-style {
    height: 10px;
    background-color: #f5f2f0;

    .radiu-body {
      height: 10px;
      border-radius: 10px 10px 0 0;
      background-color: #fff;
    }
  }

  .footer-close-btn {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 1;
    text-align: right;
    border-top: 1px solid #e5e6eb;
    background: #fff;
    padding: 10px 24px;
  }
}
</style>
