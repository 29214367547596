var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        width: "448px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "el-dialog__title" }, [_vm._v("编辑车辆")]),
          _vm._v(" "),
          _c("div", { staticClass: "title-tips" }, [
            _vm._v("将修改车主车辆信息，请慎重操作"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "editCarItem" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "validate-on-rule-change": false,
              },
            },
            [
              _c("el-form-item", { attrs: { label: "车辆ID" } }, [
                _c("div", [_vm._v(_vm._s(_vm.form.id || "-"))]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "nav-form-item", attrs: { prop: "carNo" } },
                [
                  _c("div", [
                    _c("span", { staticStyle: { color: "#DF3737" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(" 车牌号码"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list-item" }, [
                    _c(
                      "div",
                      { staticClass: "item-input" },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 8,
                            placeholder: "请输入",
                            "show-word-limit": "",
                            autocomplete: "off",
                            clearable: "",
                          },
                          model: {
                            value: _vm.form.carNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "carNo", $$v)
                            },
                            expression: "form.carNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "品牌车型", prop: "carBrandArr" } },
                [
                  _c("el-cascader", {
                    key: _vm.form.id,
                    staticStyle: { width: "100%" },
                    attrs: { props: _vm.props },
                    model: {
                      value: _vm.form.carBrandArr,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "carBrandArr", $$v)
                      },
                      expression: "form.carBrandArr",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "车VIN码", prop: "vin" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 50, placeholder: "请输入" },
                    model: {
                      value: _vm.form.vin,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "vin", $$v)
                      },
                      expression: "form.vin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: "4",
                      maxlength: 500,
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.handleClose(() => (_vm.dialogVisible = false))
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("保 存")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }