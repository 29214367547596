<template>
  <div class="main">
    <div class="content-box">
      <el-input
        maxlength="15"
        style="width:200px;margin-left:20px"
        placeholder="搜索关键词"
        v-model="keyWord"
      ></el-input>
      <el-button @click="getDataList" type="primary" style="margin-left:20px;"
        >搜索</el-button
      >
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          prop="cardNo"
          :formatter="cardNo"
          label="卡号"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="用户名"
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="rechargeNumber"
          label="充值唯一编号"
          align="center"
          min-width="100"
        ></el-table-column>

        <el-table-column
          prop="chargeUserMobile"
          align="center"
          label="充值手机号"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          align="center"
          label="被充值手机号"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="金额"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="balance"
          label="余额"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="time"
          label="时间"
          align="center"
          min-width="140"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="type"
          label="类型"
          align="center"
          :formatter="type"
          min-width="120"
        ></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          min-width="180"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              v-if="
                scope.row.refundCompleted !== true &&
                  scope.row.type != 1 &&
                  scope.row.type != 4 &&
                  scope.row.type != 5 &&
                  scope.row.type != 6 &&
                  scope.row.type != 7 &&
                  scope.row.type != 8 &&
                  scope.row.drawback == 0
              "
              @click="refundHandler(scope.row)"
              >退款
            </el-button>
            <el-button
              type="primary"
              v-if="
                scope.row.type != 4 &&
                  scope.row.type != 5 &&
                  scope.row.type != 6 &&
                  scope.row.type != 7 &&
                  scope.row.type != 8 &&
                  scope.row.drawback != 0
              "
              disabled
              >已退款
            </el-button>

            <!--                      <el-link @click="editCardHandler(scope.row)" icon="el-icon-edit">编辑</el-link>-->
            <!--                    <el-link>使用记录</el-link>-->
            <!--                    <el-link @click="lossCardHandler(scope.row)">挂失</el-link>-->
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        title="退款"
        :visible.sync="dialogVisibleRefund"
        @close="handleCloseDialog"
        width="40%"
      >
        <div class="order">
          <el-form
            ref="formRefund"
            :model="formDataRefund"
            label-width="100px"
            :rules="formRule"
          >
            <div class="order-1">
              <div class="text">用户名：</div>
              {{ data.userName }}
            </div>
            <div class="order-1">
              <div class="text">金额：</div>
              {{ data.amount }}
            </div>
            <div class="order-1">
              <div class="text">手机号：</div>
              {{ data.mobile }}
            </div>
            <el-form-item
              class="nav-form-item"
              label="退款金额："
              prop="amount"
            >
              <el-input
                maxlength="6"
                type="text"
                v-model="formDataRefund.amount1"
                style="width:200px;"
                placeholder="请输入退款金额"
                clearable
              />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="
              handleCloseDialog(() => {
                dialogVisibleRefund = false;
              })
            "
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confimRefundHandler"
            :loading="buttonLoading"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { getChongzhiRecods, refundOrder } from "@/api/registerUser";
import { getToken } from "@/utils/auth";
import { Decimal } from "decimal.js";

export default {
  data() {
    return {
      tableData: [],
      userId: this.$route.query.userId,
      keyWord: "",
      page: 1,
      pageSize: 10,
      loading: true,
      total: 0,
      data: {},
      dialogVisibleRefund: false,
      formDataRefund: {
        amount: "",
        recordId: "",
        amount1: "",
      },
      buttonLoading: false,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    refundHandler(row) {
      this.data = row;
      this.formDataRefund.recordId = row.id;
      this.formDataRefund.amount = "";
      this.formDataRefund.amount1 = row.amount;
      this.dialogVisibleRefund = true;
    },
    confimRefundHandler() {
      if (
        this.formDataRefund.amount1 <= 0 ||
        this.formDataRefund.amount1 > this.data.amount
      ) {
        this.$message.error("退款金额应在0到充值金额之间哦");
        return;
      }
      if (this.buttonLoading) {
        return;
      }
      this.$refs.formRefund.validate((valid) => {
        if (valid) {
          this.$confirm("确认退款?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.buttonLoading = true;
              refundOrder(this.formDataRefund)
                .then((res) => {
                  this.buttonLoading = false;
                  if (res.message.code != 0) {
                    this.$message.error(res.message.message);
                  } else {
                    this.$message({
                      message: "操作成功",
                      type: "success",
                    });
                    this.handleCloseDialog(() => {
                      this.dialogVisibleRefund = false;
                    });
                    this.getDataList();
                  }
                })
                .catch((err) => {
                  this.buttonLoading = false;
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
    cardNo(row) {
      if (row.cardNo) {
        return row.cardNo;
      } else {
        return "-";
      }
    },
    type(row) {
      switch (row.type) {
        case 0:
          return "充值";
          break;
        case 1:
          return "消费";
          break;
        case 2:
          return "赠送";
          break;
        case 3:
          return "系统充值";
          break;
        case 4:
          return "退款";
          break;
        case 5:
          return "退款失败";
          break;
        case 6:
          return "转款";
          break;
        case 7:
          return "订单退款";
          break;
        case 8:
          return "提现";
          break;
        case 9:
          return "扣除赠送";
          break;
        default:
          return "";
          break;
      }
    },
    getDataList() {
      this.loading = true;
      let param = {
        userId: this.userId,
        keyWord: this.keyWord,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getChongzhiRecods(param).then((res) => {
        this.tableData = [...res.data.records];
        this.total = res.data.total;
        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    handleCloseDialog(done) {
      this.formDataRefund.amount1 = "";
      done();
    },
  },
  watch: {
    "formDataRefund.amount1": {
      handler(v) {
        if (v) {
          this.formDataRefund.amount = new Decimal(this.formDataRefund.amount1)
            .times(100)
            .toString();
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}

.order {
  display: flex;
  flex-direction: column;
  color: #333;

  .order-1 {
    padding: 11px 0px;
    display: flex;

    .text {
      color: #606266;
      font-weight: 600;
      text-align: right;
      padding-right: 12px;
      width: 100px;
    }
  }
}
</style>
