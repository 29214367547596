<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="500px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <template slot="title">
      <div class="title">
        <div class="el-dialog__title">编辑车辆</div>
        <div class="title-tips">将修改车主车辆信息，请慎重操作</div>
      </div>
    </template>
    <div style="margin: 0 30px">
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="80px"
        :validate-on-rule-change="false"
      >
        <el-form-item label="车辆ID">
          <div>{{ form.id || "-" }}</div>
        </el-form-item>
        <el-form-item label="车牌号码" prop="carNo">
          <el-input v-model="form.carNo" :maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="品牌车型" prop="carBrandArr">
          <el-cascader
            :key="form.id"
            v-model="form.carBrandArr"
            style="width: 100%"
            :props="props"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="车VIN码" prop="vin">
          <el-input v-model="form.vin" :maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="form.remark"
            :maxlength="500"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose(() => (dialogVisible = false))"
        >取 消</el-button
      >
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getCarBrandList,
  getCarList,
  getSeriesList,
  modifyUserCar,
} from "@/api/car";
export default {
  props: {},
  data() {
    return {
      dialogVisible: false,
      form: {
        id: "",
        carNo: "",
      },
      rules: {
        carNo: [
          { required: true, message: "请输入车牌号码", trigger: "blur" },
          {
            min: 7,
            max: 8,
            message: "请输入7或8位的牌号码",
            trigger: "blur",
          },
        ],
        carBrandArr: [
          {
            required: true,
            message: "请输入品牌车型",
            trigger: "blur",
          },
        ],
        vin: [
          {
            required: true,
            message: "请输入车VIN码",
            trigger: "blur",
          },
        ],
      },
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          console.log("🚀 ~ file: editCarDialog.vue:75 ~ node:", node);

          const { level, value } = node;
          if (level === 0) {
            getCarBrandList().then((res) => {
              // 解析二维数组
              let list2D = Object.values(res.data);
              let list = list2D.flat();
              resolve(
                list.map((item) => {
                  let { id, brandName } = item;
                  return {
                    value: id,
                    label: brandName,
                  };
                })
              );
            });
          } else if (level === 1) {
            getSeriesList(value).then((res) => {
              // 解析二维数组
              let list2D = Object.values(res.data);
              let list = list2D.flat();
              resolve(
                list.map((item) => {
                  let { id, seriesName } = item;
                  return {
                    value: id,
                    label: seriesName,
                  };
                })
              );
            });
          } else if (level === 2) {
            getCarList(value).then((res) => {
              // 解析二维数组
              let list2D = Object.values(res.data);
              let list = list2D.flat();
              resolve(
                list.map((item) => {
                  let { id, carName, year } = item;
                  return {
                    value: id,
                    label: `${year}款 ${carName}`,
                    leaf: true,
                  };
                })
              );
            });
          }
        },
      },
    };
  },
  methods: {
    show(row) {
      this.form = Object.assign({}, this.form, row);
      let { brandId, carInfoId, seriesId } = row;
      if (brandId && carInfoId && seriesId) {
        this.$set(this.form, "carBrandArr", [brandId, seriesId, carInfoId]);
      }
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });

      this.dialogVisible = true;
    },
    handleClose(done) {
      this.$refs.form.resetFields();
      this.form = {};
      done();
    },
    handleConfirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 拼接参数
          let { carBrandArr, carNo, id, remark, vin } = this.form;
          let params = {
            carInfoId: carBrandArr[carBrandArr.length - 1],
            carNo,
            id,
            remark,
            vin,
          };
          modifyUserCar(params)
            .then((res) => {
              if (res.message.code === 0) {
                this.$message.success("修改成功");
                this.$emit("onload");
                this.dialogVisible = false;
              } else {
                this.$message.success("修改失败");
              }
            })
            .catch((e) => {
              this.$message.success("修改失败");
              console.log("🚀 ~ file: editCarDialog.vue:188 ~ e:", e);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: baseline;
}
.title-tips {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  margin-left: 10px;
}
</style>
