<template>
  <div class="main">
    <div class="content-box">
      <div class="top-title">
        <div>
          <span>车主：{{ mobile }}</span>
        </div>
        <div>
          <el-input maxlength="15" style="width: 280px" placeholder="请输入车牌号码" clearable v-model="keyWord"
            @clear="getDataList">
            <el-button slot="append" @click="getDataList" type="primary" icon="el-icon-search">搜索</el-button>
          </el-input>
          <el-button style="margin-left: 10px;height: 30px;" size="small" type="primary" icon="el-icon-arrow-left"
            @click="handleBack">返回</el-button>
        </div>
      </div>
    </div>
    <div class="content-box">
      <el-table v-loading="loading" border :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="车辆ID" align="center" min-width="60"></el-table-column>
        <el-table-column prop="carNo" :formatter="carNoFormatter" label="车牌号码" align="center"
          min-width="100"></el-table-column>
        <el-table-column prop="carName" label="品牌车型" align="center" min-width="160"
          show-overflow-tooltip></el-table-column>

        <el-table-column prop="vin" align="center" label="车VIN码" min-width="160"></el-table-column>
        <el-table-column prop="mobiles" align="center" label="关联其他车主" min-width="140">
          <template slot-scope="{ row }">
            <el-popover placement="top-start" :disabled="!(row.mobiles && row.mobiles.length > 1)" title=""
              trigger="hover">
              <div class="flex-y-center">
                <div v-for="item in row.mobiles" :key="item" style="line-height: 26px">
                  {{ item }}
                </div>
              </div>
              <div slot="reference" class="u-flex-xy">
                <div slot="reference">
                  {{ (row.mobiles || {})[0] || "" }}
                </div>
                <el-tag size="mini" style="margin-left: 10px" v-if="row.mobiles && row.mobiles.length > 1">
                  {{ "+" + row.mobiles.length }}
                </el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center" min-width="120"></el-table-column>
        <el-table-column prop="lastUpdateTime" label="最新编辑时间" align="center" min-width="160"></el-table-column>
        <el-table-column prop="lastUpdateBy" label="编辑人" align="center" min-width="120"></el-table-column>
        <el-table-column label="操作" align="center" min-width="180" fixed="right">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="primary">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="pageSize"
        :total="total"></el-pagination>
    </div>
    <editCarDialog ref="editCarDialog" @onload="getDataList"></editCarDialog>
  </div>
</template>
<script>
import { getUserCars, modifyUserCar } from "@/api/car";
import { Decimal } from "decimal.js";
import editCarDialog from "./editCarDialog.vue";

export default {
  components: { editCarDialog },
  data() {
    return {
      tableData: [],
      mobile: this.$route.query.mobile,
      keyWord: "",
      page: 1,
      pageSize: 10,
      loading: true,
      total: 0,
      data: {},
      dialogVisibleRefund: false,
      formDataRefund: {
        amount: "",
        recordId: "",
        amount1: "",
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    refundHandler(row) {
      this.data = row;
      this.formDataRefund.recordId = row.id;
      this.formDataRefund.amount = "";
      this.dialogVisibleRefund = true;
    },
    carNoFormatter(row) {
      if (row.carNo) {
        return row.carNo;
      } else {
        return "-";
      }
    },
    getDataList() {
      this.loading = true;
      let param = {
        carNo: this.keyWord || undefined,
        mobile: this.mobile,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getUserCars(param)
        .then((res) => {
          this.loading = false;
          if (res.message.code !== 0) {
            this.$message.error(res.data);
            return;
          }
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
        .catch((e) => {
          console.log("🚀 ~ file: carManagement.vue:95 ~ e:", e);

          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    handleBack() {
      this.$router.back();
    },
    handleEdit(row) {
      this.$refs.editCarDialog.show(row);
    },
    onEditSuccess() { },
  },
  watch: {
    "formDataRefund.amount1": {
      handler: function (v) {
        this.formDataRefund.amount = new Decimal(this.formDataRefund.amount1)
          .times(100)
          .toString();
        console.log("amount:" + this.formDataRefund.amount);
      },
    },
  },
};
</script>
<style scoped lang="scss">
.top-title {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  line-height: 40px;
}

.top-title span {
  margin: 0 20px;
  color: #333;
}

.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}

.order {
  display: flex;
  flex-direction: column;
  color: #333;

  .order-1 {
    padding: 11px 0px;
    display: flex;

    .text {
      color: #606266;
      font-weight: 600;
      text-align: right;
      padding-right: 12px;
      width: 100px;
    }
  }
}

.u-flex-xy {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-y-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.u-flex-xy {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-y-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
